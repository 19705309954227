import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

export const name = 'ttGlbLoader';

export default async function ttGlbLoader(projectName: string, modelName: string): Promise<Array<THREE.Object3D>> {
    let glb = new Array;
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');
    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);
    if (projectName === "karavanke" || projectName === "karavanke2") {
        glb = new Array();
        await gltfLoader.loadAsync(`/models/${projectName}/models/${modelName}`).then((gltf) => {
            glb.push(gltf.scene);
        });
    }
    else if (projectName === "sentvid") {
        glb = new Array<THREE.Object3D>(1);
        await Promise.allSettled([
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_1.glb`).then((gltf) => { glb[0] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_2.glb`).then((gltf) => { glb[1] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_3.glb`).then((gltf) => { glb[2] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_4.glb`).then((gltf) => { glb[3] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_5.glb`).then((gltf) => { glb[4] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_6.glb`).then((gltf) => { glb[5] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_7.glb`).then((gltf) => { glb[6] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_8.glb`).then((gltf) => { glb[7] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_9.glb`).then((gltf) => { glb[8] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_10.glb`).then((gltf) => { glb[9] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_11.glb`).then((gltf) => { glb[10] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_12.glb`).then((gltf) => { glb[11] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/Sentvid_2_13.glb`).then((gltf) => { glb[12] = (gltf.scene); }),
            //
                gltfLoader.loadAsync(`/models/sentvid/models/vse_400000_15000.glb`).then((gltf) => { glb[0] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/vse_400000_20000.glb`).then((gltf) => { glb[0] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/vse_400000_30000.glb`).then((gltf) => { glb[0] = (gltf.scene); }),
            //
                // gltfLoader.loadAsync(`/models/sentvid/models/1_1000000_20000.glb`).then((gltf) => { glb[0] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/2_1000000_20000.glb`).then((gltf) => { glb[1] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/3_1000000_20000.glb`).then((gltf) => { glb[2] = (gltf.scene); }),
                // gltfLoader.loadAsync(`/models/sentvid/models/4_1000000_20000.glb`).then((gltf) => { glb[3] = (gltf.scene); }),
        ]);
        for (var scene of glb) {
            if (scene) {
                scene.up.set(0.0, 0.0, 1.0);
                const box = new THREE.Box3;
                box.setFromObject(scene);
                const center = new THREE.Vector3;
                scene.position.set(458453, 106550, 316);
                // scene.position.set(-center.x, -center.y, -center.z);
                scene.rotateX(3.14 / 2.0);
                // scene.matrix.elements[12] = center.x;
                // scene.matrix.elements[13] = center.y;
                // scene.matrix.elements[14] = center.z;
                box.getCenter(center);
                scene.updateWorldMatrix(true, true);
                scene.updateMatrix();
                console.log("done");
            }
        }
        return glb;
    }
    else
        console.error("Project model name not set!: " + projectName);
    return glb;
}
